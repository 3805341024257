export const beautyPersonalizedContent = {
  backgroundImg: "home-beleza.png",
  arrowImage: "mulher-home.png",
  supportImage: "batom-home.png",
  titleText: "Crédito em grupo para quem vende produtos de beleza!",
  supportText: "Na Veriza temos crédito especial para quem vende produtos de beleza.",
  titleColor: "#135434",
  textColor: "#135434",
  carrousselImg: "maquiagem.png",
  lastArrowImage: "maquiagem-hook.png",
};